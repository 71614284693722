<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ $t('general.exportacioncontable') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div class="col-md-2">
                    <div class="card card-success card-outline">
                        <div class="card-body box-profile">
                            {{ $t('general.ldp') }}<br><br>{{ $t('general.ldp2') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <seccion-datos titulo="Programas de contabilidad" :botonGuardar="false" tipo="success">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_contaplus" class="custom-control-input custom-control-input-danger" type="checkbox" id="contaplus" true-value="1" false-value="0">
                                        <label for="contaplus" class="custom-control-label">Contaplus</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_contasol" class="custom-control-input" type="checkbox" id="contasol" true-value="1" false-value="0">
                                        <label for="contasol" class="custom-control-label">Contasol</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_dibj" class="custom-control-input" type="checkbox" id="dibj" true-value="1" false-value="0">
                                        <label for="dibj"  class="custom-control-label">DIBJ</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input custom-control-input-danger" @click="agregarprograma()" v-model="exportar_contabilidad_CobrosContaplus" type="checkbox" id="cobroscontaplus" true-value="1" false-value="0">
                                        <label for="cobroscontaplus" class="custom-control-label">CobrosContaplus</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_uniconta" class="custom-control-input" type="checkbox" id="uniconta" true-value="1" false-value="0">
                                        <label for="uniconta" class="custom-control-label">Uniconta</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input  class="custom-control-input" @click="agregarPrograma()" v-model="exportar_contabilidad_classicconta" type="checkbox" id="classicconta" true-value="1" false-value="0">
                                        <label for="classicconta" class="custom-control-label">ClassicConta</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_diamacon" class="custom-control-input custom-control-input-danger" type="checkbox" id="diamacon" true-value="1" false-value="0">
                                        <label for="diamacon" class="custom-control-label">Diamacon</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input @click="agregarPrograma()" v-model="exportar_contabilidad_director" class="custom-control-input" type="checkbox" id="director" true-value="1" false-value="0">
                                        <label for="director" class="custom-control-label">Director</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input  @click="agregarPrograma()" v-model="exportar_contabilidad_A3eco" class="custom-control-input" type="checkbox" id="a3conta" true-value="1" false-value="0">
                                        <label for="a3conta" class="custom-control-label">A3 Conta</label>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </seccion-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    data() {
        return {
            exportar_contabilidad_CobrosContaplus:'',
            exportar_contabilidad_classicconta:'',
            exportar_contabilidad_contaplus: '',
            exportar_contabilidad_director: '',
            exportar_contabilidad_contasol: '',
            exportar_contabilidad_dibj: '',
            exportar_contabilidad_uniconta: '',
            exportar_contabilidad_diamacon: '',
            exportar_contabilidad_A3eco: '',
            empresa:'',     
        }
    },
    watch: {
        id() {
            this.cargardatos();
        },
    },
    methods: {
        async cargardatos() {
            /*  modulos/pwgsapi/index.php/empresa */
            const api = new PwgsApi;
            const datos = await api.get('empresa');
            this.empresa = datos.datos;
          //  this.exportar_contabilidad_CobrosContaplus = 
            this.exportar_contabilidad_contaplus = this.empresa.exportar_contabilidad_contaplus;
            this.exportar_contabilidad_director = this.empresa.exportar_contabilidad_director;
            this.exportar_contabilidad_contasol = this.empresa.exportar_contabilidad_contasol;
            this.exportar_contabilidad_dibj = this.empresa.exportar_contabilidad_dibj;
            this.exportar_contabilidad_uniconta = this.empresa.exportar_contabilidad_uniconta;
            this.exportar_contabilidad_diamacon = this.empresa.exportar_contabilidad_diamacon;
            this.exportar_contabilidad_A3eco = this.empresa.exportar_contabilidad_A3eco;

        },
        async agregarPrograma() {
            /* put  modulos/pwgsapi/index.php/empresa */
            try {
                const subidadatos = { exportar_contabilidad_contaplus: this.exportar_contabilidad_contaplus, exportar_contabilidad_director: this.exportar_contabilidad_director, exportar_contabilidad_contasol: this.exportar_contabilidad_contasol, exportar_contabilidad_dibj: this.exportar_contabilidad_dibj, exportar_contabilidad_uniconta: this.exportar_contabilidad_uniconta, exportar_contabilidad_diamacon: this.exportar_contabilidad_diamacon, exportar_contabilidad_A3eco: this.exportar_contabilidad_A3eco };
                const api = new PwgsApi;
                await api.put('empresa/1', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    mounted() {
        this.cargardatos();
    },
}
    

</script>
